<template>
  <div class="tasks">
    <div class="padding_top"></div>

    <b-container class="bkonline_tasks" v-if="tasks">
      <b-row align-h="center">
        <b-col cols="auto">
          <h1 class="page_title">Состояние</h1>
        </b-col>
      </b-row>

      <b-row class="select_level_row" align-h="center" align-v="center">
        <b-col cols="auto">
          <p class="select_level_title">Уровень</p>
        </b-col>

        <b-col cols="4" md="2">
          <b-form-select
            :disabled="loading"
            @change="levelChanger()"
            class="select_task_level"
            v-model="selectedLevel"
            :options="levels"
            text-field="level"
            value-field="level"
          ></b-form-select>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" md="8">
          <b-row class="tasks_wrapper">
            <div
              class="task_card__wrapper"
              v-for="(task, index) in tasks"
              :key="index"
            >
              <button
                :disabled="loading"
                @click="goToEditor('TaskEditor', 'edit', index)"
                class="task_card completed"
              >
                <p class="task_card__number">{{ task.num }}</p>
              </button>
            </div>

            <div class="task_card__wrapper">
              <button
                class="task_card closed"
                @click="addTask()"
                :disabled="loading"
              >
                <p class="task_card__number">+</p>
              </button>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import axios from "axios";
moment.locale("ru");

export default {
  name: "Tasks",
  data() {
    return {
      levels: [],
    };
  },
  methods: {
    ...mapActions(["getAdminTasks", "getUser"]),
    goToEditor(pathName, type, activeTaskIndex) {
      if (this.$route.path != pathName) {
        this.$router.push({ name: pathName });

        localStorage.setItem("typeTask", type);
        localStorage.setItem("activeTask", activeTaskIndex);
      }
    },
    async levelChanger() {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getAdminTasks({ level: this.selectedLevel });

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    async addTask() {
      this.$store.commit("SET_LOADING", true);

      await axios
        .put("/admin/tasks", {
          level: this.selectedLevel,
          num: this.tasks.length + 1,
        })
        .then(async (res) => {
          this.$bvToast.toast("Задание добавлена");
          await this.levelChanger();
          this.$store.commit("SET_LOADING", false);
        })
        .catch((e) => {
          this.$store.commit("SET_LOADING", false);

          this.$bvToast.toast(`${e}`);
          console.error(e);
        });
    },
  },
  computed: {
    tasks() {
      return this.$store.state.bkonline.tasks;
    },
    user() {
      return this.$store.state.user.user;
    },
    loading() {
      return this.$store.state.loading;
    },
    selectedLevel: {
      get() {
        return this.$store.state.bkonline.selectedLevel;
      },
      set(value) {
        this.$store.state.bkonline.selectedLevel = value;
        localStorage.setItem("selectedLevel", value);
      },
    },
  },
  async mounted() {
    if (localStorage.getItem("token")) {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getUser();

        if (this.user.role_id == 1) {
          await axios.get("/admin/levels").then((res) => {
            this.levels = res.data;
          });

          await this.levelChanger();
        } else {
          this.$bvToast.toast(
            "У вас нет прав администратора для редактирования заданий"
          );
        }

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    } else {
      this.$store.commit("SET_LOADING", false);

      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style lang="scss">
.tasks_wrapper {
  position: relative;
  padding-bottom: 30px;
}
.task_card__wrapper {
  border: 5px solid $gray;
}

@media only screen and (max-width: 768px) {
  .task_card__wrapper {
    height: 25vw;
    width: 25vw;
  }
}
@media only screen and (min-width: 768px) {
  .task_card__wrapper {
    height: 5vw;
    width: 5vw;
  }
}

.task_card {
  height: 100%;
  width: 100%;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_card.await_execution {
  background-color: $accent;
}
.task_card.completed {
  background-color: $green;
}
.task_card.closed {
  background-color: $gray__light;
}
.task_card.editing {
  background-color: $orange;
}
.task_card__number {
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  margin: 0;
}
.task_card__lvl {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: $black;
}
.bkonline_tasks {
  padding-top: 30px;
}

.select_task_level:focus {
  outline: none;
}

.select_task_level {
  padding: 5px 10px;
  margin: 0;

  text-align: center;
  color: $light_gray__medium;
  font-weight: 700;
  font-size: 20px;

  background-color: unset;
  border-radius: $border;
  border: 2px solid rgba($color: $light_gray__medium, $alpha: 0.3);
}

.select_level_title {
  margin: 0;
  text-align: end;
  font-weight: 300;
  font-size: 20px;
  color: $light_gray__dark;
}

.select_level_row {
  margin-top: 15px;
  margin-bottom: 30px;
}
</style>
